.App {
  text-align: center;
}
@media (min-width: 100px) {
  html {
    max-width: 390px !important;
    margin: 0 auto !important;
    background: #f9f9f9 !important;
    height:100%;
    /*min-height:100px;*/
    box-shadow: 6px 6px 0px 0px #f6f6f6 ;
  }
}

@font-face {
  font-family: Gotham Rounded;
  src: url("https://watertownbaby.s3.us-east-1.amazonaws.com/static/fonts/GothamRounded-Book.otf");
  font-weight: normal;
}

@font-face {
  font-family: Gotham Rounded;
  src: url("https://watertownbaby.s3.us-east-1.amazonaws.com/static/fonts/GothamRounded-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: Gotham Rounded;
  src: url("https://watertownbaby.s3.us-east-1.amazonaws.com/static/fonts/GothamRounded-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: Gotham Rounded;
  src: url("https://watertownbaby.s3.us-east-1.amazonaws.com/static/fonts/GothamRounded-Light.otf");
  font-weight: lighter;
}

p,
h1,
h2,
h3,
h4 {
  color: #1b365d;
}

a {
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: bold;
}

* {
  font-family: Gotham Rounded;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
